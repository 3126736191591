import AxiosService from "@/services/AxiosService";
class TrafficbackService {
  async getTrafficbackSettings(id) {
    return await AxiosService.get(`/subaccount/${id}/trafficback-settings`)
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async setTrafficbackSettings(payload, id) {
    return await AxiosService.post(
      `/subaccount/${id}/trafficback-settings`,
      payload
    )
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}
export default new TrafficbackService();
