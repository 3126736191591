import trafficbackService from "@/api/trafficbackService";

export default {
  data() {
    return {
      trafficback_settings: false,
      trafficList: [
        { name: "accept", value: "allowed_input" },
        { name: "decline", value: "denied_input" },
        { name: "skiped", value: "skiped_input" },
        { name: "subscribed", value: "subscribed_input" },
        { name: "unsupported", value: "unsupported_input" },
      ],
      trafficURLList: {
        allowed_input: "",
        denied_input: "",
        skiped_input: "",
        subscribed_input: "",
        unsupported_input: "",
      },
    };
  },
  created() {
    if (this.platform.site) {
      this.getTrafficbackSettings();
    }
  },
  computed: {
    trafficSettings() {
      return {
        trafficback_settings: this.trafficback_settings,
        trafficback_urls: this.trafficURLList,
      };
    },
  },
  methods: {
    setTrafficbackSettings() {
      const id = this.platform.id;
      const payload = {
        trafficback_settings: {
          trafficback_enable: Number(this.trafficback_settings),
          allowed: this.trafficURLList.allowed_input || null,
          denied: this.trafficURLList.denied_input || null,
          skipped: this.trafficURLList.skiped_input || null,
          subscribed: this.trafficURLList.subscribed_input || null,
          unsupported: this.trafficURLList.unsupported_input || null,
        },
      };
      trafficbackService.setTrafficbackSettings(payload, id).then((res) => {
        if (res && res.status == 200) {
          this.$alert({
            type: "success",
            title: this.$t("sent_success"),
            text: res.message,
          });
        } else {
          this.$alert({
            title: this.$t("sent_error"),
            text: res.message,
          });
        }
      });
    },
    getTrafficbackSettings() {
      const id = this.platform.id;
      trafficbackService.getTrafficbackSettings(id).then((res) => {
        if (res && res.status == 200) {
          const data = res.data.trafficback_settings;
          this.trafficback_settings = data.trafficback_enable || "";
          this.trafficURLList.allowed_input = data.allowed || "";
          this.trafficURLList.denied_input = data.denied || "";
          this.trafficURLList.skiped_input = data.skipped || "";
          this.trafficURLList.subscribed_input = data.subscribed || "";
          this.trafficURLList.unsupported_input = data.unsupported || "";
        } else {
          this.$alert({
            title: this.$t("sent_error"),
            text: res.message,
          });
        }
      });
    },
  },
};
