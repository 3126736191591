import AxiosService from "@/services/AxiosService";

class DomainService {
  async buyDomain(payload) {
    return await AxiosService.post("/buy-domain", payload)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getDomain(payload) {
    return AxiosService.get("/user-domains", { params: payload })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getDomainZones() {
    return AxiosService.get("/domains/zones")
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getDomainModal(payload) {
    return AxiosService.get("/domains", { params: payload })
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getCurrentDomain(id) {
    return AxiosService.get(`/subaccount/${id}/get-domain`)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  setCurrentDomain(payload) {
    return AxiosService.post(`/subaccount/${payload.id}/set-domain`, {
      domain: payload.domain,
    })
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async trashDomain(id, payload) {
    return AxiosService.patch(`/domains/${id}/trash`, payload)
      .then((response) => {
        return response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}
export default new DomainService();
